import React from 'react';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Button from '@material-ui/core/Button';

export default function Footer(){

    return(<>
        <br />
        <center><strong>Siga-nos nas redes sociais</strong></center>
        <br />
        <center>                
            <Button href="https://www.facebook.com/concreteja/" target="_blank" variant="outlined" color="primary">
                <FacebookIcon />
            </Button>
            <span className="spaceButton"></span>
            <Button href="https://twitter.com/ConcreteJa" target="_blank" variant="outlined" color="primary">
                <TwitterIcon />
            </Button>
            <span className="spaceButton"></span>
            <Button href="https://www.instagram.com/concrete.ja/" target="_blank" variant="outlined" color="primary">
                <InstagramIcon />
            </Button>     
            <span className="spaceButton"></span>
            <Button href="https://www.linkedin.com/company/concrete-já/" target="_blank" variant="outlined" color="primary">
                <LinkedInIcon />
            </Button>  
        </center>     
        <hr />
        <center>
            <strong>Concrete já</strong><br />
            Concrete Já Intermediações e Serviços LTDA<br />
            CNPJ 34.970.450/0001-29<br />
            Av. Artur de Queiroz, 293, 1º Andar, Sala 21, Casa Branca<br />
            Santo André - SP, CEP 09015-510<br />
            Fones: (11) 99547-9167 | (11) 4462-7575<br />
            <p><Button variant="outlined" color="default" target="_blank" href="https://concreteja.com.br">www.concreteja.com.br</Button></p>            
        </center>        
    </>);
};