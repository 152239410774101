import firebase, { auth } from '../firebase';

function setLocalUser(user){
    if(user){
        localStorage.setItem('localUserAuth', JSON.stringify(user));
        return;
    }
    localStorage.removeItem('localUserAuth');
}

function getLocalUser(){
    let strJson = localStorage.getItem('localUserAuth')
    if(strJson){
        let user = JSON.parse(strJson);
        return user;
    }
    return null;        
}

async function signOut(){
    await auth.signOut();
    setLocalUser(null);
}

function isAuthenticated(){
    return getLocalUser() != null;    
}

async function getIdToken(){
    if(isAuthenticated()){
        return new Promise((resolve, reject)=>{
            auth.onAuthStateChanged(user=>{
                if(user){
                    let token = user.getIdToken(true);
                    resolve(token);
                }
                else{
                    reject(null);
                }
            });
        });                
    }
    return Promise.reject(null);
}

export default { getLocalUser, setLocalUser, signOut, isAuthenticated, getIdToken, firebase }