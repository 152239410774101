import { functions } from '../firebase';
import { toBase64 } from '../utils';

//functions.useFunctionsEmulator("http://localhost:5001");        

const services = {
    obterPessoa: async function(cnpj){        
        const data = await functions.httpsCallable('getPerson')(toBase64({doc: cnpj}));
        return data;
    }
};

export default services;