import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import IconHome from '@material-ui/icons/Home';
import IconExit from '@material-ui/icons/ExitToApp';
import auth from '../../auth';
import { Link } from 'react-router-dom';

//const useStyles = makeStyles((theme) => ({}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


export default function MenuAppBar() {    
  const classes = useStyles();
  const [authState, setAuth] = useState(auth.isAuthenticated());
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState(auth.getLocalUser());
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);    
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const goHome = (event) => {
    setAnchorEl(null);    
    window.open('https://concreteja.com.br');    
  };

  const logout = async (event) => {    
    setAnchorEl(null);
    await auth.signOut();    
    document.location.href = document.location.protocol+'//'+document.location.host;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <Link to="/">
                    <img src="img/logo.png" style={{height: '35px'}} alt="Concrete já" />
                </Link>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            
          </Typography>
          {authState && (
            <div>
                <Link style={{ color: '#FFF', textDecorationLine: 'none' }} to="/login"><strong>{user.displayName}</strong></Link>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <Avatar alt={user.displayName} src={user.photoURL} />                
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem onClick={goHome}><IconHome /> Site Concrete já</MenuItem>
                <MenuItem onClick={logout}><IconExit /> Sair</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
}
