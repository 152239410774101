import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import auth from './auth';
import FormularioEmpresa from './components/FormularioEmpresa';
import Login from './components/Login';
import Home from './components/Home';

function Routes() {    
    return (
        <Switch>
            <RouteAuthorized path="/formulario-empresa">
                <FormularioEmpresa />
            </RouteAuthorized>
            <Route path="/login">
                <Login />
            </Route>            
            <Route path="*">
                <Home />
            </Route>            
        </Switch>
    );
};

function RouteAuthorized(props) {
    
    var user = auth.getLocalUser();

    if(user == null)
    { 
        localStorage.setItem('returnUrl', typeof(props.path) == "string" ? props.path : props.path[0]);
        return <Redirect to="/login" />;
    }

    return <Switch><Route {...props} /></Switch>;    
}

export default Routes;