import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import auth from '../auth';
import Button from '@material-ui/core/Button';
import IconExit from '@material-ui/icons/ExitToApp';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import { AlertPop } from '../utils';
import PubSub from 'pubsub-js';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
    appBar: {
      position: 'relative',
    },
    layout: {
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5)
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-between'
      
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1)
    },
    formControl: {
      margin: theme.spacing(0),
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(0),
    },  
  }));

export default function Login(){

    const classes = useStyles();

    const [rendering, setRendering] = useState(true);
    const [localUser, setLocalUser] = useState(auth.getLocalUser() || {uid:null});  
    const [msg, setMsg] = useState('Aguarde, preparando o login...');    

    const [uiConfig, setUiConfig] = useState({
        //signInSuccessUrl: localStorage.getItem('returnUrl'),
        tosUrl:'https://www.concreteja.com.br/termos.html',
        privacyPolicyUrl: 'https://www.concreteja.com.br/privacidade.html',
        signInOptions:[
            auth.firebase.auth.EmailAuthProvider.PROVIDER_ID,
            auth.firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            auth.firebase.auth.FacebookAuthProvider.PROVIDER_ID
            
        ],
        callbacks:{            
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
                setMsg('Aguarde um momento, estamos efetuando seu login em nossa plataforma...');
                setRendering(true);                
                return true;
              },
              signInFailure: function(error) {
                console.log('signInFailure', arguments);
              },
              uiShown: function() {
                setRendering(false);
                setMsg('Escolha um metodo de login abaixo.');
              }            
        }
    });

    const logoffUser = async () => {
        setRendering(true);
        await auth.signOut();        
        setTimeout(() => {
            document.location.href = document.location.protocol+'//'+document.location.host;            
        });        
    };

    const renderOauth = () => {        
        
        setTimeout(() => {
            if(window.firebaseui){
                window.firebase = auth.firebase;
                try{
                    if(document.querySelector('#firebaseui-auth-container')){                        
                        let ui = new window.firebaseui.auth.AuthUI(auth.firebase.auth());                        
                        ui.start('#firebaseui-auth-container', uiConfig); 
                    }
                }catch(e){
                    //console.log('concreteja', e);
                }
                finally{
                    //setRendering(false);
                }
            }              
        }, 1000);
        return(
            <div id="firebaseui-auth-container"></div>
        );
    }

    const renderLogin = () => {
        if (localUser.uid){
            return (
            <div>
                <Grid item xs={12} md={12}>
                    <center><strong>Bem vindo(a) {localUser.displayName}</strong></center>
                    <center><p>{localUser.email}</p></center>
                    <center>                        
                        <Button href="/formulario-empresa" color="primary" variant="contained">
                            <HomeWorkIcon />Minha empresa
                        </Button>                        
                    </center>
                </Grid>
                <br/>
            </div>);
        }
        else if(msg){
            return (
                <div>
                    <Grid item xs={12} md={12}>                        
                        <center><p>{msg}</p></center>
                    </Grid>                
                    <br/>                
                </div>);
        }
        return (<span></span>);
    }

    useEffect(()=>{

        auth.firebase.auth().onAuthStateChanged(user=>{             
            
            if(user && localUser.uid == null){
        
                let name = user.displayName.split(' ');
                let localUserAuth = {
                    displayName: name.length > 1 ? name[0]+' '+name[name.length-1] : name[0],
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    photoURL: user.photoURL,
                    uid: user.uid
                };
                auth.setLocalUser(localUserAuth);
                setLocalUser(localUserAuth);
                setRendering(false);
                setMsg('');
                let returnUrl = localStorage.getItem('returnUrl');
                AlertPop.success('Login bem sucedido.');
                PubSub.publish('MenuAppBar.Show');
                if(returnUrl){
                    setTimeout(() => {
                        localStorage.removeItem('returnUrl');
                        document.location.href = returnUrl;                        
                    }, 1000);
                }                
            } 
            else if(localUser.uid){
                setRendering(false);
            }            
        });

        let user = auth.getLocalUser();
        if(user){            
            //setRendering(false);
        }        
    },[]);

    return (<>
        <CssBaseline />
        <main className={classes.layout}>
            <Paper className={classes.paper}>                        
                <center><a href="/"><img style={{height: 'auto', width: '50%'}} src={"img/logo.png"} alt="logo" /></a></center>                        
                {renderLogin()}
                <hr/>
                <center>{rendering ? <CircularProgress size={34} /> : <span></span>}</center>
                {localUser.uid ? 
                    <center><Button variant="contained" color="default" onClick={()=>logoffUser()}><IconExit /> Sair</Button></center>
                :
                    renderOauth()}                
            </Paper>
        </main>
        
    </>);
}