import React from 'react';
import Button from '@material-ui/core/Button';
import auth from '../auth';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ContactsIcon from '@material-ui/icons/Contacts';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
    }
}));

export default function Home(){

    const classes = useStyles();

    return (
        <div>
            <Paper className={classes.paper}>
            <center>
                <a href="/">
                    <img className="imgLog" src={"img/logo.png"} alt="Concrete já" />
                </a>
                <hr />                
                <h1>Bem vindo a Concrete já</h1>
                <p><strong>Ambiente de negócios da Concrete já</strong></p>
                <p>Neste ambiente você poderá cadastrar sua concreteira e seus produtos.</p>
                <p>Faça o login em nossa plataforma e cadastre-se.</p>
                <p></p>                
                <p><strong>Por que vender mais e on-line, é muito melhor...</strong></p>
                {auth.getLocalUser() ? 
                    <Button variant="contained" color="primary" href="/formulario-empresa">
                       <HomeWorkIcon />Minha empresa
                    </Button>
                :
                    <Button variant="contained" color="primary" href="/formulario-empresa">
                        <ContactsIcon />Fazer login e cadastrar
                    </Button>             
                }
                <p></p>
                <Button href="https://concreteja.page.link/qbvQ" target="_blank" variant="outlined" color="primary"><WhatsAppIcon /> Chamar no WhatsApp</Button>                
            </center>            
            </Paper>
        </div>
    );
}