import React, {useEffect, useState} from 'react';
import './App.css';
import Container from '@material-ui/core/Container';
import Routes from './Routes';
import MenuAppBar from './components/layout/MenuAppBar'
import auth from './auth';
import Footer from './components/layout/Footer';
import PubSub from 'pubsub-js';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function App() {  

  const classes = useStyles();
  const [user, setUser] = useState(auth.getLocalUser());
  const [showBackDrop, setShowBackDrop] = useState(false);

  useEffect(()=>{
    PubSub.subscribe('MenuAppBar.Show', (e, data)=>{
        setUser(auth.getLocalUser());
    });

    PubSub.subscribe('Backdrop.Show', (e, flag)=>{
      setShowBackDrop(flag);
  });

    return ()=>{
      PubSub.unsubscribe('MenuAppBar.Show');
      PubSub.unsubscribe('Backdrop.Show');
    };
  },[]);

  return (<>   
    {user && <MenuAppBar />}
    <Container>    
      <Routes />      
    </Container>    
    <Footer />
    <Backdrop className={classes.backdrop} open={showBackDrop}>
      <CircularProgress color="inherit" />
    </Backdrop>    
  </>);
};