let traducaoTable = {
    body: {
      emptyDataSourceMessage: 'Nenhum registro para exibir',                                      
      addTooltip: 'Adicionar',
      deleteTooltip: 'Apagar item',
      editTooltip: 'Editar item',
      filterRow: {
        filterTooltip: 'Filtrar'
      },
      editRow: {
        deleteText: 'Deseja realmente apagar este item?',
        cancelTooltip: 'Cancelar',
        saveTooltip: 'Salvar'
      }                                      
    },
    toolbar: {                                      
      searchTooltip: 'Pesquisar',
      searchPlaceholder: 'Pesquisar'
    },
    header: {
      actions: 'Ações'
    },
    pagination: {
      labelRowsSelect: 'linhas',
      labelDisplayedRows: '{count} de {from}-{to}',
      firstTooltip: 'Primeira página',
      previousTooltip: 'Página anterior',
      nextTooltip: 'Próxima página',
      lastTooltip: 'Última página'
    }
  };

function toStringFormat(number) {
  var strNumber = number.toLocaleString('pt-BR');
  var alStrNumber = strNumber.split(',');
  if (alStrNumber.length == 2) {
      strNumber = strNumber + (alStrNumber[1].length == 1 ? '0' : '');
  }
  else
  {
      strNumber = strNumber + ',00';
  }
  return 'R$ ' + strNumber;
}  
  export default { traducaoTable, toStringFormat }