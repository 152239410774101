import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import { AlertPop, validCnpj, showBackDrop } from '../utils';
import BusinessIcon from '@material-ui/icons/Business';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import CancelIcon from '@material-ui/icons/Cancel';
import HomeIcon from '@material-ui/icons/Home';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import MapIcon from '@material-ui/icons/Map';
import RoomIcon from '@material-ui/icons/Room';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveIcon from '@material-ui/icons/Save';
import MaterialTable from 'material-table';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { useForm } from "react-hook-form";
import ufCidades from '../utils/metadados';
import layout from './layout/utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import { storage, database } from '../firebase';
import auth from '../auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import MaskedInput from 'react-text-mask';
import services from '../services';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },  
    paper: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3),
        },
    },/*    
    gridList: {
      width: 500,
      height: 450,
    },*/
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
}));

function TextMask(props) {
  const { inputRef, ...other } = props;
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={props.mask}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

function TextMaskCNPJ(props) {  
  return (
    <TextMask {...props} mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/,'.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/]} />
  );
}

function TextMaskCEP(props) {  
  return (
    <TextMask {...props} mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/,'-', /\d/, /\d/, /\d/]} />
  );
}

function TextMaskTelFixo(props) {  
  return (
    <TextMask {...props} mask={['(',/\d/, /\d/, ')', /\d/, /\d/,/\d/, /\d/,'-', /\d/, /\d/, /\d/, /\d/]} />
  );
}

function TextMaskCelular(props) {  
  return (
    <TextMask {...props} mask={['(',/\d/, /\d/, ')', /\d/, /\d/,/\d/, /\d/,/\d/,'-', /\d/, /\d/, /\d/, /\d/]} />
  );
}

const estados = [];

ufCidades.ufCidades.estados.forEach(itemUf => {
  estados.push(itemUf.sigla);
  estados[itemUf.sigla] = itemUf.cidades;
});

export default function FormularioEmpresa(){

    //const cidades = ufCidades.ufCidades.estados[25].cidades;


    const classes = useStyles();
    const { register, handleSubmit, errors, setValue } = useForm();
    const inputFile = useRef('');

    const [checkedBomb, setCheckedBomb] = useState(false);
    const [checkedAcab, setCheckedAcab] = useState(false);
    const [checkedCorte, setCheckedCorte] = useState(false);
    const [cidades, changeCidades] = useState([]);
    const [cidade, changeCidade] = useState('');
    const [estado, changeEstado] = useState('');
    const [urlLogo, setUrlLogo] = useState('img/bitornera-flat.png');
    const [blobLogo, setBlobLogo] = useState(null);
    const [upLoading, setUpLoading] = useState(false);
    const [cnpjInvalido, setCnpjInvalido] = useState(false);
    const [loadCnpj, setLoadCnpj] = useState(false);
    const [loadCep, setLoadCep] = useState(false);
    const [cepInvalido, setCepInvalido] = useState(false);
    const [dragFile, setDragFile] = useState(false);
    const [edicao, setEdicao] = useState(false);
    const [cadastro, setCadastro] = useState({});

    const [stateTable, setStateTable] = useState({
        columns: [
          { title: 'FCK', field: 'fck', 
            lookup: { 
              15: 'FCK 15', 
              18: 'FCK 18', 
              20: 'FCK 20',
              25: 'FCK 25',
              30: 'FCK 30',
              35: 'FCK 35',
              40: 'FCK 40',
              45: 'FCK 45',
            } 
          },
          { title: 'Brita', field: 'brita', 
            lookup: { 
              1: 'Brita 0', 
              2: 'Brita 1', 
              3: 'Brita 0+1'  
            }  
          },
          {
            title: 'R$' , 
            field: 'valor', 
            type: 'numeric',
            render: rowData => (<span>{isNaN(rowData.valor) ? '' : layout.toStringFormat(rowData.valor)}</span>),
            validate: rowData => {              
              if(isNaN(rowData.valor)) return { isValid: false, helperText: 'Informe um valor válido.' };
              if(rowData.valor <= 0) return { isValid: false, helperText: 'Necessário maior que zero.' };
              return true;
            }
          }
        ],
        data: [],
    });    

    const changeFileUpload = e => {
      setUpLoading(true);
      if(e.currentTarget.files.length){
        const file = e.currentTarget.files[0];
        if(file.type.search('image/') < 0){
          AlertPop.warning(`Arquivo "${file.name}" não permitido.`)
          e.currentTarget.value = '';
          return;
        }        
        const url = URL.createObjectURL(file);
        setBlobLogo(file);
        setUrlLogo(`url(${url})`);
        setUpLoading(false);
      }      
    };

    const changeCnpj = async e => {
      if(e.target.value.length === 18){
        let cnpj = e.target.value.replace(/([^\d])+/gim, '');
        if(!validCnpj(cnpj)){
          setCnpjInvalido(true);
          return;
        }
        setCnpjInvalido(false);      
        setLoadCnpj(true);
        try
        {
          setTimeout(() => {
            debugger
            setLoadCnpj(false);
          }, 10000);

          let pessoa = await services.obterPessoa(cnpj);
          if(pessoa.data){
            setValue('nomeFantasia', pessoa.data.nomeFantasia);
            setValue('razaoSocial', pessoa.data.nome);
          }
          setLoadCnpj(false);  
        }
        catch (e){ }
        finally
        {
          setLoadCnpj(false);
        }
      }      
    }

    const changeCep = async e => {
      if(e.target.value.length === 10){    
        let cepDigitado = e.target.value;
        let cep = e.target.value.replace(/([^\d])+/gim, '');
        setLoadCep(true);
        let result = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        if(result.ok){
          let endereco = await result.json();
          
          if(!endereco.erro){
            setValue('endereco', endereco.logradouro);
            setValue('bairro', endereco.bairro);
            setValue('uf', endereco.uf);
            setValue('cidade', endereco.localidade);
            changeEstado(endereco.uf);
            changeCidades(estados[endereco.uf]);
            changeCidade(endereco.localidade);
            setCepInvalido(false);
          }
          else {
            setCepInvalido(true);
          }
        }        
        setLoadCep(false);
      }      
    };

    const onSubmit = async data => {

      showBackDrop(true);

      const user = auth.getLocalUser();
      
      if(blobLogo){

        const path = `business/${user.uid}/`+blobLogo.name;      

        await storage
          .ref(path)
          .put(blobLogo);
  
        let urlStorage = await storage.ref(path).getDownloadURL();
        
        setUrlLogo(`url(${urlStorage})`);     
  
        data.urlLogo = urlStorage;

      }
      else if(cadastro.urlLogo){
        data.urlLogo = cadastro.urlLogo;        
      }

      data.variantes = JSON.parse(data.variantes);

      data.usuarioNome = user.displayName;
      data.usuarioEmail = user.email;
      data.cidade = cidade;
      data.uf = estado;

      database.ref(`business/${user.uid}/`).set(data);      

      setEdicao(true);

      AlertPop.success(edicao ? 'Dados salvos com sucesso.' : 'Cadastro concluído com sucesso.');      

      showBackDrop(false);

    };    

    useEffect(()=>{
      
      showBackDrop(true);

      const user = auth.getLocalUser();      

      const fnGetData = async () => {
        
        let dataRef = await database
        .ref(`business/${user.uid}/`)
        //.ref(`business/uyHph5On5IXFTvi3UN3kZmceYhs2/`)
        .orderByKey()
        .once('value');
        let cadastro = dataRef.val();

        if(cadastro) {

          setCheckedCorte(!!cadastro.valorCorte);

          setCheckedAcab(!!cadastro.valorAcabamento);

          setCheckedBomb(!!(cadastro.valorBombeamentoAutoBomba || cadastro.wvalorBombeamentoLancaer || cadastro.valorBombeamentoArrasto))
          
          setUrlLogo(`url(${cadastro.urlLogo})`);          

          for(var key in cadastro){
            setValue(key, cadastro[key]);
          }          
          
          setStateTable((prevState) => {
            let data = cadastro.variantes;
            return { ...prevState, data };
          });          
          
          setEdicao(true);
          setCadastro(cadastro);
          changeEstado(cadastro.uf);
          changeCidades(estados[cadastro.uf]);
          changeCidade(cadastro.cidade);          
        }
        else{
          setEdicao(false);
        }

        showBackDrop(false);
      };

      fnGetData();

    },[]);
    
    //https://material-ui.com/static/images/grid-list/camera.jpg
    //img/bitornera-flat.png
    //onClick={e=>inputFile.current.click()}
    //{upLoading ? <CircularProgress size={34} /> : <ImageSearchIcon style={{ fontSize: 100 }} />}

    return (<>
        <CssBaseline />
        <main className={classes.layout}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h5" align="center">            
                    Formulário de cadastro de concreteiras
                </Typography>                
                <br/>
                <Grid container spacing={3} style={{marginBottom: '6px'}}>                  
                  <Grid item md={2} className="logoImg" style={{backgroundImage: urlLogo, borderWidth: dragFile ? '2px' : '1px', backgroundColor: dragFile ? '#fffdaa' : 'transparent' }} disabled={upLoading}>
                    <div className="logoImgBody">
                      <ImageSearchIcon className="iconUpImg" />
                      <input onDragEnter={e=>setDragFile(true)} onDragLeave={e=>setDragFile(false)} onDrop={e=>setDragFile(false)} className="fileUpload" type="file" id="fileUpload" name="fileUpload" ref={inputFile} accept="image/*" onChange={changeFileUpload} />
                    </div>
                  </Grid>                  
                  <Grid item md={10}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                          <FormControl fullWidth className={classes.margin}>
                              <InputLabel>CNPJ *</InputLabel>
                              <Input                            
                                startAdornment={
                                  <InputAdornment position="start">
                                    <BrandingWatermarkIcon />
                                  </InputAdornment>
                                }
                                endAdornment={
                                  <InputAdornment position="end">                                    
                                    {loadCnpj && <CircularProgress size={20} />}
                                  </InputAdornment>                                                                    
                                }
                                inputProps={{
                                  name: 'cnpj',
                                  ref: register({ required: true })
                                }}                            
                                error={!(!errors.cnpj)}
                                inputComponent={TextMaskCNPJ}
                                placeholder="12.345.678/0001-23"
                                onChange={changeCnpj}
                                disabled={loadCnpj}
                              />
                              {(errors.cnpj || cnpjInvalido) && <span className="labelRed">Informe um 'CNPJ válido'</span>}
                              
                            </FormControl>
                        </Grid>                    
                      <Grid item xs={12} md={9}>
                        <FormControl fullWidth className={classes.margin}>
                            <InputLabel>Nome fantasia *</InputLabel>
                            <Input                            
                              startAdornment={
                                <InputAdornment position="start">
                                  <BusinessIcon />
                                </InputAdornment>
                              }
                              inputProps={{
                                maxLength: 250,
                                name: 'nomeFantasia',
                                ref: register({ required: true })
                              }}                            
                              error={!(!errors.nomeFantasia)}    
                              disabled={loadCnpj}                          
                            />
                            {errors.nomeFantasia && <span className="labelRed">Informe o 'Nome fantasia'</span>}
                            {loadCnpj && <LinearProgress />}
                          </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                          <FormControl fullWidth className={classes.margin}>
                              <InputLabel>Razão social *</InputLabel>
                              <Input                            
                                startAdornment={
                                  <InputAdornment position="start">
                                    <LocationCityIcon />
                                  </InputAdornment>
                                }
                                inputProps={{
                                  maxLength: 250,
                                  name: 'razaoSocial',
                                  ref: register({ required: true })
                                }}                            
                                error={!(!errors.razaoSocial)}       
                                disabled={loadCnpj}                  
                              />
                              {errors.razaoSocial && <span className="labelRed">Informe o 'Razão social'</span>}
                              {loadCnpj && <LinearProgress />}
                            </FormControl>
                        </Grid>                  
                    </Grid>
                  </Grid>                  
                </Grid>                
                <Grid container spacing={3}>
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>CEP *</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <LoyaltyIcon />
                              </InputAdornment>
                            }
                            endAdornment={
                              <InputAdornment position="end">                                    
                                {loadCep && <CircularProgress size={20} />}
                              </InputAdornment>                                                                    
                            }                            
                            inputProps={{
                              name: 'cep',
                              ref: register({ required: true })
                            }}
                            inputComponent={TextMaskCEP}
                            placeholder="91.999-999"
                            error={!(!errors.cep)}
                            disabled={loadCep}
                            onChange={changeCep}                  
                          />
                          {(errors.cep || cepInvalido) &&  <span className="labelRed">Informe um 'CEP válido'</span>}
                        </FormControl>
                    </Grid>                     
                    <Grid item xs={12} md={5}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Endereço *</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <HomeIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              maxLength: 250,
                              name: 'endereco',
                              ref: register({ required: true })
                            }}                            
                            error={!(!errors.endereco)}   
                            disabled={loadCep}
                          />
                          {errors.endereco && <span className="labelRed">Informe o 'Endereço da empresa'</span>}
                          {loadCep && <LinearProgress />}
                        </FormControl>
                    </Grid>  
                    <Grid item xs={12} md={5}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Bairro *</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <HomeIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              maxLength: 250,
                              name: 'bairro',
                              ref: register({ required: true })
                            }}                            
                            error={!(!errors.bairro)}
                            disabled={loadCep}                        
                          />
                          {errors.bairro && <span className="labelRed">Informe o 'Bairro'</span>}
                          {loadCep && <LinearProgress />}
                        </FormControl>
                    </Grid>      
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={1}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Número</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <AccountBalanceIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              type:'number',
                              maxLength: 5,
                              name: 'numeroEndereco',
                              ref: register
                            }}
                          />
                        </FormControl>
                    </Grid>   
                    <Grid item xs={12} md={2}>
                      <FormControl fullWidth className={classes.margin}>
                          <Autocomplete
                              id="size-small-standard"
                              size="small"
                              options={estados}
                              getOptionLabel={(option) => option}
                              defaultValue={''} 
                              value={estado}
                              onChange={(event, value)=>{
                                changeCidades(estados[value]);
                                changeEstado(value);
                                changeCidade('');
                              }}  
                              noOptionsText="Opção não encontrada"                            
                              renderInput={(params) => {
                                params.InputProps.startAdornment = 
                                (<InputAdornment position="start">
                                  <MapIcon />
                                </InputAdornment>);
                                return (<TextField {...params}
                                  variant="standard"
                                  label="Estado(UF)*" 
                                  placeholder="" />
                              )}}
                              disabled={loadCep}
                            />          
                            {false && <span className="labelRed">Informe o 'Estado'</span>}
                            {loadCep && <LinearProgress />}                          
                        </FormControl>
                    </Grid>                                       
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth className={classes.margin}>                          
                            <Autocomplete
                              id="size-small-standard"
                              size="small"
                              options={cidades}
                              getOptionLabel={(option) => option}
                              defaultValue={''} 
                              value={cidade}
                              onChange={(event, value)=>changeCidade(value)}  
                              noOptionsText="Opção não encontrada"                            
                              renderInput={(params) => {
                                params.InputProps.startAdornment = 
                                (<InputAdornment position="start">
                                <AccountBalanceIcon />
                              </InputAdornment>);
                                return (<TextField {...params}
                                  variant="standard"
                                  label="Cidade *" 
                                  placeholder="" />
                              )}}
                              disabled={loadCep}
                            />          
                            {false && <span className="labelRed">Informe a 'Cidade'</span>}
                            {loadCep && <LinearProgress />}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Latitude </InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <RoomIcon />
                              </InputAdornment>
                            }
                            inputProps={{                              
                              type:'number',
                              name: 'latitude',
                              ref: register
                            }}   
                          />
                        </FormControl>
                    </Grid>      
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Longitude </InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <RoomIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              type:'number',                              
                              name: 'longitude',
                              ref: register
                            }}   
                          />
                        </FormControl>
                    </Grid>
                </Grid>
                <br/>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={3}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Email empresa *</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              type:"email",
                              name: 'email',
                              ref: register({
                                required: true,
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i                                  
                                }
                               })
                            }} 
                            error={!(!errors.email)}
                          />
                          {errors.email && <span className="labelRed">Informe um 'Email válido'</span>}
                        </FormControl>
                    </Grid>           
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Telefone *</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <PhoneIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              type:"tel",
                              name: 'telefone',
                              ref: register({required: true})                              
                            }}
                            inputComponent={TextMaskTelFixo}                            
                            error={!(!errors.telefone)}
                            placeholder="(11)9999-9999"
                          />
                          {errors.telefone && <span className="labelRed">Informe um 'Telefone'</span>}
                        </FormControl>
                    </Grid>      
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>Celular *</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <PhoneAndroidIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              type:"tel",
                              name: 'celular',
                              ref: register({required: true})
                            }} 
                            error={!(!errors.celular)}
                            inputComponent={TextMaskCelular}
                            placeholder="(11)99999-9999"
                          />
                          {errors.celular && <span className="labelRed">Informe um 'Celular'</span>}
                        </FormControl>
                    </Grid>   
                    <Grid item xs={12} md={3}>
                      <FormControl fullWidth className={classes.margin}>
                          <InputLabel>WhatsApp</InputLabel>
                          <Input                            
                            startAdornment={
                              <InputAdornment position="start">
                                <WhatsAppIcon />
                              </InputAdornment>
                            }
                            inputProps={{
                              type:"tel",
                              name: 'whatsapp',
                              ref: register
                            }} 
                            inputComponent={TextMaskCelular}
                            placeholder="(11)99999-9999"
                          />
                        </FormControl>
                    </Grid>                                                            
                </Grid>                
                <Grid>                
                <br/>
                <br/>
                <fieldset className={errors.variantes ? 'labelRed' : ''}>
                    <legend><ShoppingCartIcon/> <span className="legIcon">Variantes do concreto *</span></legend>
                    {errors.variantes && <span className="labelRed">Adicione as variantes de concreto que pretende vender.</span>}
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12}>
                            <input type="hidden" ref={register({required: true})} name="variantes" value={stateTable.data.length > 0 ? JSON.stringify(stateTable.data) : '' } />
                            <MaterialTable
                                title="Especificação de valores concreto"
                                columns={stateTable.columns}
                                data={stateTable.data}
                                editable={{
                                    onRowAdd: (newData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                          resolve();
                                          setStateTable((prevState) => {
                                              errors.variantes = null;
                                              const data = [...prevState.data];
                                              data.push(newData);
                                              return { ...prevState, data };
                                          });
                                        });
                                    }),
                                    onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                          resolve();
                                          if (oldData) {
                                              setStateTable((prevState) => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                return { ...prevState, data };
                                              });
                                          }
                                        });
                                    }),
                                    onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                          resolve();
                                          setStateTable((prevState) => {
                                              const data = [...prevState.data];
                                              data.splice(data.indexOf(oldData), 1);
                                              return { ...prevState, data };
                                          });
                                        });
                                    }),
                                }}
                                localization={layout.traducaoTable}
                                options={{
                                  actionsColumnIndex: -1
                                }}
                                />       
                        </Grid>
                    </Grid>                                             
                </fieldset>
                </Grid>
                <br/>
                <br/>                
                <Grid>
                <fieldset>
                    <legend><TransferWithinAStationIcon /><span className="legIcon"> Extras além do concreto</span></legend>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch
                                            checked={checkedBomb}
                                            onChange={e=>setCheckedBomb(!checkedBomb)}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Bombeamento?"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                    </Grid>                    
                    {checkedBomb ? 
                      <fieldset>                        
                        <legend>Valor por M³</legend>
                        <Grid container spacing={3} >
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth className={classes.margin}>
                              <InputLabel>Auto bomba</InputLabel>
                              <Input                            
                                  startAdornment={
                                  <InputAdornment position="start">
                                      R$
                                  </InputAdornment>
                                  }
                                  inputProps={{
                                    type: 'number',
                                    name: 'valorBombeamentoAutoBomba',
                                    ref: register
                                  }}
                                  placeholder="30,00"                                  
                              />                              
                              </FormControl>                            
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth className={classes.margin}>
                              <InputLabel>Lança</InputLabel>
                              <Input                            
                                  startAdornment={
                                  <InputAdornment position="start">
                                      R$
                                  </InputAdornment>
                                  }
                                  inputProps={{
                                    type: 'number',
                                    name: 'valorBombeamentoLanca',
                                    ref: register
                                  }}
                                  placeholder="30,00"
                              />
                              </FormControl>                            
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <FormControl fullWidth className={classes.margin}>
                              <InputLabel>De arrasto</InputLabel>
                              <Input                            
                                  startAdornment={
                                  <InputAdornment position="start">
                                      R$
                                  </InputAdornment>
                                  }
                                  inputProps={{
                                    type: 'number',
                                    name: 'valorBombeamentoArrasto',
                                    ref: register
                                  }}
                                  placeholder="30,00"
                              />
                              </FormControl>                            
                          </Grid>   
                        </Grid>                                      
                        </fieldset>: ''}
                    <hr/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch
                                            checked={checkedAcab}
                                            onChange={e=>setCheckedAcab(!checkedAcab)}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Acabamento de piso?"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        {checkedAcab ? 
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth className={classes.margin}>
                            <InputLabel>Valor por M²</InputLabel>
                            <Input                            
                                startAdornment={
                                <InputAdornment position="start">
                                    R$
                                </InputAdornment>
                                }
                                inputProps={{
                                  type: 'number',
                                  name: 'valorAcabamento',
                                  ref: register
                                }}
                                placeholder="10,00"
                            />
                            </FormControl>
                        </Grid> : ''}
                    </Grid>      
                    <hr/>  
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="top"
                                        control={<Switch
                                            checked={checkedCorte}
                                            onChange={e=>setCheckedCorte(!checkedCorte)}
                                            color="primary"
                                            name="checkedB"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />}
                                        label="Corte de piso?"
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        {checkedCorte ? 
                        <Grid item xs={12} md={3}>
                            <FormControl fullWidth className={classes.margin}>
                            <InputLabel>Valor por M(linear)</InputLabel>
                            <Input                            
                                startAdornment={
                                <InputAdornment position="start">
                                    R$
                                </InputAdornment>
                                }
                                inputProps={{
                                  type: 'number',
                                  name: 'valorCorte',
                                  ref: register
                                }}
                                placeholder="10,00"
                            />
                            </FormControl>
                        </Grid> : ''}
                    </Grid>                                 
                </fieldset>
                </Grid>
                <br/>
                <br/>
                <fieldset>
                  <legend><MonetizationOnIcon /><span className="legIcon">Dados bancários</span></legend>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth className={classes.margin}>
                          
                          <TextField
                            fullWidth
                            label="Banco *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{                              
                              maxLength: 250,
                              name: 'empresaBanco',
                              ref: register({ required: true })
                            }}
                            placeholder="Itaú"
                            error={!(!errors.empresaBanco)}
                            />
                            {errors.empresaBanco && <span className="labelRed">Informe o 'Seu banco'</span>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth className={classes.margin}>                          
                          <TextField
                            fullWidth
                            label="Agência *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{                              
                              maxLength: 10,
                              name: 'empresaBancoAgencia',
                              ref: register({ required: true })
                            }}
                            placeholder="1234-5"
                            error={!(!errors.empresaBancoAgencia)}
                          />
                          {errors.empresaBancoAgencia && <span className="labelRed">Informe a 'Agencia de seu banco'</span>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth className={classes.margin}>                          
                          <TextField
                            fullWidth
                            label="Conta corrente *"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{                              
                              maxLength: 20,
                              name: 'empresaBancoContaCorrente',
                              ref: register({ required: true })
                            }}
                            placeholder="000123-4"                          
                            error={!(!errors.empresaBancoContaCorrente)}
                        />
                        {errors.empresaBancoContaCorrente && <span className="labelRed">Informe a 'Sua conta corrente'</span>}
                        </FormControl>
                    </Grid>                      
                  </Grid>                  
                </fieldset> 
                <br/> 
                <center>
                    <Button variant="contained" color="primary" type="submit"><SaveIcon />{edicao ? 'Salvar' : 'Cadastrar'}</Button>
                    <span className="spaceButton"></span>
                    <Button variant="outlined" color="secondary" href="/"><CancelIcon />Cancelar</Button>
                </center>                
            </Paper>
          </form>
        </main>
    </>);
}


