import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyAkEXMOp3SGUrr5CK22ik66Lya_tfQgw6c",
    authDomain: 'bus.concreteja.com.br',
    databaseURL: "https://concreteja-hom.firebaseio.com",
    projectId: "concreteja-cb4d6",
    storageBucket: "concreteja-cb4d6.appspot.com",
    messagingSenderId: "1038530269830",
    appId: "1:1038530269830:web:fd42cacbf0d79561c77707",
    measurementId: "G-6RECCHF1Y0"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();
const functions = firebase.functions();

export { auth, storage, database, functions, firebase as default }